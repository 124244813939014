.mySwiper {
    background: #fff;
}

.swiperitem {
  position: relative;
  padding-bottom: 2px;
}

.swiperitem img {
  display: block;
  position: relative;
  z-index: 1;
  max-width: 100%; /* adicionando esta linha para ajustar o tamanho da imagem */
}

.swiperitem:before,
.swiperitem:after {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 8px;
  z-index: 2;
}

.swiperitem:before {
  top: 0;
  background: url('/images/serrilha.png') repeat-x bottom;
  background-size: 20px;
}

.swiperitem:after {
  bottom: 0;
  background: url('/images/serrilha-base.png') repeat-x bottom;
  background-size: 20px;
}
